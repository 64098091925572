import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
  APP_INITIALIZER,
  ApplicationRef,
  InjectionToken,
  Injector,
  NgModule,
  PLATFORM_ID,
} from '@angular/core';
import {
  initializeApp as initializeFireApp,
  provideFirebaseApp,
} from '@angular/fire/app';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import {
  provideClientHydration,
  withI18nSupport,
  withNoHttpTransferCache,
} from '@angular/platform-browser';
import {
  ServiceWorkerModule,
  SwRegistrationOptions,
} from '@angular/service-worker';
import { Capacitor } from '@capacitor/core';
import { Auth } from '@freelancer/auth';
// Deep imports to enable better tree-shaking
// eslint-disable-next-line local-rules/validate-freelancer-imports
import { FakeCollectionsWidgetComponent } from '@freelancer/datastore/collections-testing/fake-collections-widget/fake-collections-widget.component';
// eslint-disable-next-line local-rules/validate-freelancer-imports
import { FakeCollectionsWidgetTestingComponent } from '@freelancer/datastore/collections-testing/fake-collections-widget/testing/fake-collections-widget-testing.component';
import { SSR_MAPS_API_KEY } from '@freelancer/google-maps';
import { PwaInitialState } from '@freelancer/initial-state';
import { LocationInitialUrl, LocationModule } from '@freelancer/location';
import {
  LOGIN_AUTH_SERVICE,
  LOGIN_REDIRECT_SERVICE,
  Login,
  LoginModule,
} from '@freelancer/login';
import { NetworkModule } from '@freelancer/network';
import { NotificationsModule } from '@freelancer/notifications';
import { PWA_CONFIG, PwaModule } from '@freelancer/pwa';
import { SeoModule } from '@freelancer/seo';
import { ThemeComponent } from '@freelancer/theme';
import {
  ErrorTrackingGlobalAccess,
  FlTraceService,
} from '@freelancer/tracking';
import { UpdateNativeAlertModule } from '@freelancer/ui/update-native-alert';
import { UserAgent } from '@freelancer/user-agent';
import { toNumber } from '@freelancer/utils';
import 'capacitor-plugin-service-worker';
import { filter, switchMap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { AppCommonModule } from './app-common.module';
import { ShellRoutingModule } from './app-shell/shell-routing.module';
import { AppComponent } from './app.component';
import { ToastAlertsModule } from './toast-alerts/toast-alerts.module';

// YOU ARE PROBABLY LOOKING FOR app-common.module.ts !!!

// /!\ DO NOT ADD ANYTHING IN THERE WITHOUT TALKING TO FRONTEND INFRA FIRST /!\
// Application-wide code should be avoided as much as possible; does your code
// needs:
// - to be loaded on ALL logged-in pages?
// - AND to be loaded on ALL logged-out pages?
// - AND to be loaded on the Admin?
// - ...
// Probably not.

const CORE_WEB_VITALS_TRACKING = new InjectionToken<LocationInitialUrl>(
  'LocationInitialUrl',
);
const PWA_INITIAL_STATE = new InjectionToken<PwaInitialState>(
  'PwaInitialState',
);

export function initializeApp(
  platformId: Object,
  locationInitialUrl: LocationInitialUrl,
): () => void {
  return () => {
    if (isPlatformBrowser(platformId)) {
      // Store original url before internal routing happens
      locationInitialUrl.init();

      // Mark the app as bootstrapped for inline scripts
      // eslint-disable-next-line no-restricted-globals
      if (window.webapp) {
        // eslint-disable-next-line no-restricted-globals
        window.webapp.applicationBootstrapped = true;
      }
    }
  };
}

@NgModule({
  imports: [
    AppCommonModule,
    ShellRoutingModule,
    LocationModule,
    LoginModule,
    SeoModule,
    PwaModule,
    UpdateNativeAlertModule,
    NetworkModule,
    ToastAlertsModule,
    ServiceWorkerModule.register('ngsw-worker.js'),
    provideFirebaseApp(() =>
      initializeFireApp({
        apiKey: environment.firebase.apiKey,
        projectId: environment.firebase.projectId,
        messagingSenderId: environment.firebase.messagingSenderId,
        appId: environment.firebase.appId,
      }),
    ),
    provideMessaging(() => getMessaging()),
    NotificationsModule,
    ThemeComponent,
    environment.useFakes
      ? FakeCollectionsWidgetComponent
      : FakeCollectionsWidgetTestingComponent,
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: SwRegistrationOptions,
      deps: [PLATFORM_ID, Auth, Injector, UserAgent],
      useFactory: (
        platformId: Object,
        auth: Auth,
        injector: Injector,
        userAgent: UserAgent,
      ) => ({
        /* eslint-disable no-restricted-properties, no-restricted-globals */
        enabled:
          environment.production &&
          isPlatformBrowser(platformId) &&
          // disable on Edge Legacy due to prefetching issues
          // https://github.com/angular/angular/issues/27261
          userAgent.getUserAgent().getEngine()?.name !== 'EdgeHTML' &&
          // Special conditions for Android Capacitor
          // where we don't want to enable the service worker.
          //  * Disable on Android WebView 90 as the Chromium team broke it
          //    Ref: https://bugs.chromium.org/p/chromium/issues/detail?id=1204098
          //  * T242019: Disable on Android OS version lower than 7.0
          //    because it doesn't support Android ServiceWorkerController.
          //
          // Note: we use the Capacitor API directly here as the Pwa service
          // injects ApplicationRef, which is not available at that point.
          (Capacitor.getPlatform() !== 'android' ||
            (!userAgent.getUserAgent().getBrowser().version?.startsWith('90') &&
              (toNumber(userAgent.getUserAgent().getOS().version) ?? 0) >=
                /* Nougat */ 7)) &&
          // Also disable the service worker for iOS 17.0 and 17.1
          // because a bug in WebKit causes the service worker cache to break.
          // This affects Capacitor and ALL iOS browsers (inc. "Chrome")
          // Ref: https://github.com/angular/angular/issues/50378
          // allow if not iOS
          (userAgent.getUserAgent().getOS().name !== 'iOS' ||
            // allow if not one of the problematic versions
            (!userAgent.getUserAgent().getOS().version?.startsWith('17.0') &&
              !userAgent.getUserAgent().getOS().version?.startsWith('17.1'))) &&
          // Setting a ?sw=false query param or DISABLE_NGSW=true cookie
          // disables the SW to allow testing without the Service Worker
          // running.
          !window.location.search.includes('sw=false') &&
          !window.document.cookie.includes('DISABLE_NGSW=true'),
        /* eslint-enable no-restricted-properties, no-restricted-globals */
        registrationStrategy: () =>
          // Register the SW when the user logs in & the application is idle
          auth.isLoggedIn().pipe(
            filter(isLoggedIn => isLoggedIn),
            switchMap(() =>
              injector
                .get(ApplicationRef)
                .isStable.pipe(filter(isStable => isStable)),
            ),
          ),
        scope: '/',
      }),
    },
    {
      provide: LOGIN_REDIRECT_SERVICE,
      useExisting: Login,
    },
    {
      provide: LOGIN_AUTH_SERVICE,
      useExisting: Auth,
    },
    {
      provide: CORE_WEB_VITALS_TRACKING,
      useExisting: LocationInitialUrl,
    },
    {
      provide: PWA_INITIAL_STATE,
      useExisting: PwaInitialState,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [
        DOCUMENT,
        PLATFORM_ID,
        CORE_WEB_VITALS_TRACKING,
        PWA_INITIAL_STATE,
        FlTraceService,
        ErrorTrackingGlobalAccess,
      ],
      multi: true,
    },
    {
      provide: PWA_CONFIG,
      useValue: { appIcon: environment.pwaConfig.appIcon },
    },
    {
      provide: SSR_MAPS_API_KEY,
      deps: [PLATFORM_ID],
      useFactory: (platformId: string) => {
        if (isPlatformServer(platformId)) {
          return process.env.SSR_MAPS_API_KEY ?? environment.mapsConfig.apiKey;
        }

        return undefined;
      },
    },
    provideClientHydration(withI18nSupport(), withNoHttpTransferCache()),
  ],
})
export class AppModule {}
