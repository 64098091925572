import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * Empty component to replace the FakeCollectionsWidget component
 * when running the UI tests (so it doesn't block the view)
 */
@Component({
  selector: 'fl-fake-collections',
  standalone: true,
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FakeCollectionsWidgetTestingComponent {}
